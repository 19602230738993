export const HERE = {
	url: 'https://geocoder.cit.api.here.com/6.2/geocode.json',
	apiKey: 'BqfHUNcUKldECFraWNY9hxrelGmje66cN6emMi2TrtY',
	app_id: 'JVKC6oug6PWOJi8TfzaL',
	app_code: 'RRu_aTpDjP93Sf_48wtHbQ',
};
export const mapbox = {
	public_api_key:
		'pk.eyJ1IjoibWF0aW5kb3ciLCJhIjoiY2xkd3gzcHJ0MDQ4eTNwbXlzOGp5NnVlMSJ9.mIJFMuu0LrbYJRwBykv9Kg',
};

export const google = {
	measurementId: 'G-VSR84W9RWG', // GA4
};

export const paypal = {
	clientId:
		'AS_8E-vmL1RVmJKDZ7KGo0SfYrsV5eDrEM8bC79aJ1KpWLrHsHiidmaD3akK2DN1ndjPwf9h81p1HdCr',
	clientIdSb:
		'Aa8xyYUNVnB9pDVvEZjNnMIzuxY7cBHIPPnvFzlHpKXNYhEx_Vr0Gc_i_RHjEVxMRfcteTQQ0w7Y29il',
};

export const facebook = {
	pixelId: '516648621844325',
};

export const sf = {
	apiVersion: 'v61.0',
};
export const basepath = '';
export let productTypes = new Map([
	[
		'Acoustic',
		{
			Name: 'Acoustic',
			product_code__c: 'ASTD',
			price__c: 39,
			max_width__c: 6,
			max_height__c: 8,
			max_width_alt__c: 5,
			max_height_alt__c: 10,
		},
	],
	[
		'Standard',
		{
			Name: 'Standard',
			product_code__c: 'STD',
			price__c: 30,
			max_width__c: 6,
			max_height__c: 8,
			max_width_alt__c: 5,
			max_height_alt__c: 10,
		},
	],
	[
		'Kit',
		{
			Name: 'Kit',
			product_code__c: 'KIT',
			price__c: 15,
			max_width__c: 6,
			max_height__c: 4.75,
			max_width_alt__c: 6,
			max_height_alt__c: 4.75,
		},
	],
]);
export let turnstile = {
	siteKey: '0x4AAAAAAAD9OnExkg5IyF7E',
};
export const heap = {
	production: '1624159678',
	development: '1340390440',
};
export const usermaven = {
	production: 'UMs9UEAZrU',
	development: 'UMddKnnKvC',
};
export const sentry = {
	dsn: 'https://a1546cd539d141d29f832610ce57adcf@o951854.ingest.sentry.io/5901002',
};
